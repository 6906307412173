<template>
  <div v-loading="isLoading" class="base-subscription">
    <div class="base-subscription-image">
      <div class="base-subscription-image-wrapper">
        <div
          v-if="formValue.image || image_path"
          class="base-subscription-image-file"
          v-bg-image-from-storage="formValue.image ?? image_path"
        />
        <el-empty v-else description=" " />
      </div>
      <el-upload
        ref="upload"
        action=""
        :multiple="false"
        :http-request="onSubmit"
        :before-upload="beforeUpload"
        :show-file-list="false"
        :limit="1"
      >
        <span v-if="!formValue.image">Choose image</span>
        <span v-else>Change image</span>
      </el-upload>
    </div>
    <div class="base-subscription-name">
      <div class="label">Tier</div>
      <BaseInput v-model="formValue.name" placeholder="Tier" />
    </div>
    <div class="base-subscription-description">
      <div class="label">Explanation message text</div>
      <BaseTextarea v-model="formValue.description" placeholder="Explanation message text" />
      <div class="base-subscription-message-count">{{ formValue.description.length }} / 160</div>
    </div>
    <div>
      <div class="label">Price</div>
      <div class="base-subscription-price">
        <inline-svg :src="require('@/assets/icons/dollar.svg')" />
        <BaseInput
          v-model="formValue.price"
          type="number"
          placeholder="Price"
          :left-padding="true"
        />
      </div>
    </div>
    <div class="base-subscription-active">
      <BaseCheckbox v-model="formValue.active" label="Subscription is active" />
    </div>
    <div
      class="base-subscription-delete"
      :class="{ 'remove-disabled': !removeDisabled }"
      @click="handleRemove"
    >
      Remove subscription
    </div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import InlineSvg from "vue-inline-svg";
import BaseInput from "@/components/BaseInput";
import BaseTextarea from "@/components/BaseTextarea";
import BaseCheckbox from "@/components/BaseCheckbox";

export default {
  name: "BaseSubscription",
  props: ["modelValue", "removeDisabled"],
  components: {
    InlineSvg,
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
  },
  data() {
    return {
      isLoading: false,
      image_path: "",
      subscriptions: [],
      fileList: [],
      previewImageName: "",
      previewImageURL: "",
    };
  },
  computed: {
    uid() {
      return this.$route.params.id;
    },
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleRemove(index) {
      this.$emit("remove", index);
    },
    async onSubmit(req) {
      this.isLoading = true;
      const uuid = Math.random().toString(36).slice(-6);
      const imageName = req.file.name.replace(/ /g, "-");
      const bucket_url = `${uuid}_${imageName}`;
      const ref = await firebase.storage().ref().child(bucket_url);
      await ref.put(req.file);

      this.formValue.image = bucket_url;
      this.image_path = bucket_url;

      this.isLoading = false;
    },
    beforeUpload(file) {
      this.$refs.upload.clearFiles();
      const uploadExceedsMaximumFileSize = file.size / 1024 / 1024 > this.maximumFileSize;

      if (uploadExceedsMaximumFileSize) {
        console.log(`Image size can not exceed ${this.maximumFileSize} MB!`);
      }

      const validType = file.type.split("/")[0] === "image"; // Must be an image
      if (!validType) {
        console.log("File must be an image!");
      }
      return !uploadExceedsMaximumFileSize && validType;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 12px;
  color: #878d93;
  font-size: 14px;
}

.base-subscription {
  background: #ffffff;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 8px;
  padding: 24px;
}

.base-subscription-name,
.base-subscription-price {
  margin-bottom: 24px;
}

.base-subscription-image {
  margin-bottom: 14px;
}

.base-subscription-image > div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.base-subscription-image-wrapper {
  margin-bottom: 17px;
  height: 110px;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.base-subscription :deep(.el-empty__image) {
  height: 70%;
}

.base-subscription-image img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 6px;
}

.base-subscription-image span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #0ba3a9;
}

.base-subscription-image :deep(.el-upload-list) {
  display: none;
}

.base-subscription-message-count {
  margin-bottom: 11px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
}

.base-subscription-price,
.base-subscription-active {
  position: relative;
}

.base-subscription-price svg {
  position: absolute;
  left: 21px;
  top: 21px;
}

.base-subscription-active :deep(.base-checkbox-label) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-left: 0;
  pointer-events: none;
  text-align: center;
}

.base-subscription-image-file {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.base-subscription-delete {
  margin-top: 26px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #f7685b;

  &.remove-disabled {
    pointer-events: none;
    color: rgba(166, 170, 180, 0.5);
  }
}
</style>
