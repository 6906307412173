<template>
  <textarea class="base-textarea" type="text" v-model="formValue" :placeholder="placeholder" />
</template>

<script>
export default {
  name: "BaseTextarea",
  props: ["modelValue", "placeholder"],
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-textarea {
  min-height: 120px;
  width: 100%;
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 16px 20px;
  outline: none;
  resize: vertical;
  transition: border-color 0.2s ease;
  will-change: border-color;
}

.base-textarea:active,
.base-textarea:focus {
  border-color: #0ba3a9;
}

.base-textarea::placeholder {
  color: rgba(166, 170, 180, 1);
}
</style>
