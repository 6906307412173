<template>
  <div class="subscriptions">
    <!-- Connect to Stripe -->
    <div v-loading="isLoading" v-if="!club.stripe_account_id" class="subscriptions-container">
      <div class="subscriptions-connect">
        <div class="subscriptions-connect-title">
          Connect club to Stripe <br />
          to create your first subscription product
        </div>
        <BaseButton @click="connectToStripe" small>Connect to Stripe</BaseButton>
      </div>
    </div>

    <!-- Subscriptions -->
    <div v-else class="subscriptions-container">
      <!-- Buttons -->
      <div class="subscriptions-button-wrapper">
        <div class="stripe-button-and-text">
          <BaseButton :disabled="isLoading" small @click="connectToStripe"
            >Manage your Stripe Account</BaseButton
          >
          <div class="stripe-text">
            Please notice that your Stripe account should be fully set up and connected to Klubba to
            start receiving payments from clients
          </div>
        </div>

        <div class="cancel" v-if="stateChanged" @click="cancelDeletion">Cancel changes</div>
        <BaseButton
          small
          :disabled="subscriptions.length === 3 || isLoading"
          @click="addSubscription"
        >
          Add subscription
        </BaseButton>
        <BaseButton small :disabled="!isSaveButtonVisible || isLoading" @click="saveSubscriptions">
          Save subscriptions
        </BaseButton>
      </div>

      <div class="invalid" v-if="!subscriptionsValid">Please fill in all subscription fields</div>

      <div v-loading="isLoading" v-if="subscriptions.length === 0">
        <el-empty description="No subscriptions yet" />
      </div>

      <!-- Subscriptions list -->
      <div v-loading="isLoading" v-else class="subscriptions-list">
        <BaseSubscription
          v-model="subscriptions[index]"
          v-for="(subscription, index) in subscriptions"
          :key="`subscription-${index}`"
          @remove="handleRemoveSubscription(index)"
          :removeDisabled="subscriptions.length > 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import BaseSubscription from "@/components/BaseSubscription";
import BaseButton from "@/components/BaseButton";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "Subscriptions",
  components: {
    BaseSubscription,
    BaseButton,
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      isLoading: false,
      stateChanged: false,
      removedSubscriptions: [],
      defaultSubscriptions: [],
      subscriptions: [],
    };
  },
  computed: {
    ...mapGetters(["club", "clubId", "clubSubscriptions"]),
    subscriptionsValid() {
      return this.subscriptions.every(
        (subscription) =>
          !Object.values(subscription).includes(null) && !Object.values(subscription).includes("")
      );
    },
    isSaveButtonVisible() {
      return (
        JSON.stringify(this.defaultSubscriptions) !== JSON.stringify(this.subscriptions) &&
        this.subscriptionsValid
      );
    },
  },
  methods: {
    cancelDeletion() {
      this.removedSubscriptions = [];
      this.getSubscriptions();
    },
    async connectToStripe() {
      this.isLoading = true;
      const createStripeAccount = await firebase.functions().httpsCallable("createAccount");
      const createAccountParams = {
        club: this.clubId,
        refresh: `${window.location.origin}/settings?stripeError=true`,
        return: `${window.location.origin}/settings`,
      };

      console.log("Calling createAccount with", createAccountParams);

      await createStripeAccount(createAccountParams)
        .then((response) => {
          console.log(response);
          if (response.data.isSuccess) {
            document.location.href = response.data.result.url;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.toast.error(error);
          this.isLoading = false;
        });
    },
    addSubscription() {
      this.subscriptions.push({
        name: "",
        currency: "aud",
        description: "",
        price: null,
        active: true,
        image: null,
      });
    },
    async handleRemoveSubscription(index) {
      if (this.subscriptions[index].id) {
        this.removedSubscriptions.push(this.subscriptions[index].id);
      }

      this.subscriptions = this.subscriptions
        .slice(0, index)
        .concat(this.subscriptions.slice(index + 1, this.subscriptions.length));
    },
    async saveSubscriptions() {
      this.isLoading = true;

      // Remove subscriptions
      if (this.removedSubscriptions.length > 0) {
        await Promise.all(
          this.removedSubscriptions.map(async (id) => {
            await firebase
              .firestore()
              .collection("clubs")
              .doc(this.clubId)
              .collection("subscriptions")
              .doc(id)
              .delete();
          })
        );
      }

      // Add or update subscriptions
      await Promise.all(
        this.subscriptions.map(async (sub) => {
          if (sub.id) {
            await firebase
              .firestore()
              .collection("clubs")
              .doc(this.clubId)
              .collection("subscriptions")
              .doc(sub.id)
              .update(sub);
          } else {
            await firebase
              .firestore()
              .collection("clubs")
              .doc(this.clubId)
              .collection("subscriptions")
              .add(sub);
          }
        })
      );

      this.isLoading = false;
      this.getSubscriptions();
    },
    getSubscriptions() {
      if (!this.isLoading) {
        this.subscriptions = JSON.parse(JSON.stringify(this.clubSubscriptions));
        this.defaultSubscriptions = JSON.parse(JSON.stringify(this.clubSubscriptions));

        this.$nextTick(() => {
          this.stateChanged = false;
        });
      }
    },
  },
  watch: {
    clubSubscriptions: {
      handler() {
        this.getSubscriptions();
      },
      immediate: true,
      deep: true,
    },
    removedSubscriptions: {
      handler(value) {
        console.log(value);
        this.stateChanged = true;
      },
      deep: true,
    },
    subscriptions: {
      handler() {
        this.stateChanged = true;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriptions {
  padding: 42px 0;
}

.subscriptions-container {
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
}

.stripe-button-and-text {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 40px;
}

.stripe-text {
  width: 320px;
  font-size: 13px;
  line-height: 1.2;
  margin-left: 20px;
  color: #b0b5b5;
}

.subscriptions-button-wrapper {
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.cancel {
  margin-right: 20px;
  font-weight: 500;
  color: #939d9d;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #0ba3a9;
  }
}

.subscriptions-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 56px;
}

.subscriptions-connect {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 8px;
  padding: 90px 24px;
}

.subscriptions-connect-title {
  font-style: normal;
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #707683;
  margin-bottom: 24px;
}

.invalid {
  text-align: right;
  margin-bottom: 30px;
  font-size: 14px;
  margin-top: -30px;
  color: #e32741;
}
</style>
